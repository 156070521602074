var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"color":"grey lighten-4"}},[_c('v-img',{attrs:{"aspect-ratio":16 / 9,"src":_vm.product.featurePicture}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out primary__overlay v-card--reveal display-3 wht",staticStyle:{"height":"100%"}},[_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" R"+_vm._s(_vm.product.price.toFixed(2))),_c('br'),_c('span',{staticClass:"inc-vat"},[_vm._v("(Including Vat)")])])]):_vm._e()])],1),_c('v-card-text',{staticClass:"pt-8",staticStyle:{"position":"relative"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.product.previewDocument)?_c('v-btn',_vm._g({staticClass:"mr-15 secondary--text cart__btn",attrs:{"absolute":"","fab":"","right":"","top":""},on:{"click":function($event){return _vm.openPreview(_vm.product.previewDocument)}}},on),[_c('v-icon',[_vm._v("mdi-eye")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Download Document Preview")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"secondary--text cart__btn",attrs:{"absolute":"","fab":"","right":"","top":""},on:{"click":function($event){return _vm.addProductToCart(_vm.product.id)}}},on),[_c('v-icon',[_vm._v("mdi-cart")])],1)]}}],null,true)},[_c('span',[_vm._v("Add to Cart")])]),_c('h3',{staticClass:"text-xl-h5 font-weight-light primary__text mb-2 pointer",on:{"click":function($event){return _vm.$router.push(("/products/" + (_vm.product.id)))}}},[_c('strong',[_vm._v(_vm._s(_vm.product.title))])]),_c('div',{staticClass:"font-weight-light grey--text title mb-2",domProps:{"innerHTML":_vm._s(_vm.product.content)}})],1),_c('v-divider'),_c('v-card-text',{},[(_vm.product.completionTime)?_c('h3',{staticClass:"text-caption mb-2"},[_c('strong',[_vm._v("Time taken to complete document: ")]),_c('v-chip',{attrs:{"small":""}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.product.completionTime)}})])],1):_vm._e(),(_vm.product.requiredInfo)?_c('h3',{staticClass:"text-caption mb-2"},[_c('strong',[_vm._v("Required information: ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.product.requiredInfo)}})]):_vm._e()])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }