<template>
  <div>
    <h3 class="slide__subtitle">
      <em>{{ productModel.title }}</em>
    </h3>
    <v-breadcrumbs :items="crumbs"></v-breadcrumbs>

    <v-card>
      <v-card-text>
        <v-row>
          <v-col>
            <h3>Content Preview</h3>
            <v-card>
              <v-card-text>
                <h2 class="section__title">{{ productModel.title }}</h2>
                <p>{{ productModel.content }}</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <h3>List Preview</h3>
            <product-card :product="productModel"></product-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" :to="`${$route.path}/edit`">Edit</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { Product } from "@/models/Product";
import { gql } from "apollo-boost";
import ProductCard from "@/components/Frontend/Commerce/ProductCard";

export default {
  components: {
    ProductCard,
  },
  data() {
    return {
      productModel: new Product(),
      crumbs: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/admin/dashboard",
        },
        {
          text: "DIY Documents",
          disabled: false,
          href: "/admin/products",
        },
        {
          text: "DIY Document Details",
          disabled: true,
        },
      ],
    };
  },
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
    product: {
      query: gql`
        query Product($id: ID!) {
          product(id: $id) {
            id
            title
            content
            category
            price
            featurePicture
            previewDocument
            completionTime
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result(response, key) {
        this.productModel.title = this.product.title;
        this.productModel.content = this.product.content;
        this.productModel.id = this.product.id;
        this.productModel.price = this.product.price;
        this.productModel.category = this.product.category;
        this.productModel.featurePicture = this.product.featurePicture;
        this.productModel.previewDocument = this.product.previewDocument;
        this.productModel.completionTime = this.product.completionTime;
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
