export class Product {
  constructor() {
    this.id = "";
    this.title = "";
    this.content = "";
    this.category = "";
    this.price = "";
    this.featurePicture = "";
    this.previewDocument = "";
    this.completionTime = "";
    this.requiredInfo = "";
    this.newFeaturePicture = [];
    this.newPreviewDocument = [];
    this.existingPicture = [];
    this.tagIDs = [];
    this.digitalItem = "";
    this.disclaimer = "";
  }

  get priceFloat() {
    if (this.price) {
      if (!isNaN(this.price)) return parseFloat(this.price);
    }
    return 0;
  }
}
