<template>
  <v-hover v-slot:default="{ hover }">
    <v-card color="grey lighten-4">
      <v-img :aspect-ratio="16 / 9" :src="product.featurePicture">
        <v-expand-transition>
          <div v-if="hover" class="d-flex transition-fast-in-fast-out primary__overlay v-card--reveal display-3 wht" style="height: 100%;">
            <div style="text-align: center;">
              R{{ product.price.toFixed(2) }}<br />
              <span class="inc-vat">(Including Vat)</span>
            </div>
          </div>
        </v-expand-transition>
      </v-img>
      <v-card-text class="pt-8" style="position: relative;">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="product.previewDocument" absolute class="mr-15 secondary--text cart__btn" fab right top @click="openPreview(product.previewDocument)" v-on="on">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>Download Document Preview</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn absolute class="secondary--text cart__btn" fab right top @click="addProductToCart(product.id)" v-on="on">
              <v-icon>mdi-cart</v-icon>
            </v-btn>
          </template>
          <span>Add to Cart</span>
        </v-tooltip>
        <h3 @click="$router.push(`/products/${product.id}`)" class="text-xl-h5 font-weight-light primary__text mb-2 pointer">
          <strong>{{ product.title }}</strong>
        </h3>
        <div class="font-weight-light grey--text title mb-2" v-html="product.content" />
      </v-card-text>
      <v-divider />
      <v-card-text class="">
        <h3 v-if="product.completionTime" class="text-caption mb-2">
          <strong>Time taken to complete document: </strong>
          <v-chip small><div v-html="product.completionTime"></div></v-chip>
        </h3>
        <h3 v-if="product.requiredInfo" class="text-caption mb-2">
          <strong>Required information: </strong>
          <div v-html="product.requiredInfo" />
        </h3>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import { gql } from "apollo-boost";
import { User } from "@/models/User";
export default {
  props: {
    product: {
      type: Object,
      default: {
        id: "",
        price: 0,
        title: "",
        content: "",
        featurePicture: "",
        completionTime: "",
      },
    },
  },
  data() {
    return {
      me: null,
    };
  },
  apollo: {
    me: {
      query: gql`
        query {
          me {
            id
            role
            firstName
            lastName
          }
        }
      `,
      fetchPolicy: "network-only",
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result(response, key) {
        if (response.data) {
          this.user = new User();
          this.user.id = this.me.id;
          this.user.role = this.me.role;
          this.user.firstName = this.me.firstName;
          this.user.lastName = this.me.lastName;
        }
      },
      error(error) {
        this.errorMsg = error;
      },
    },
  },
  methods: {
    addProductToCart(id) {
      const self = this;
      this.$apollo.queries.me
        .refetch()
        .then(() => {
          if (self.me != null) {
            self.$emit("addProduct", id);
          } else {
            self.$emit("showLogin", { state: true, productId: id });
          }
        })
        .catch(() => {
          self.$emit("showLogin", { state: true, productId: id });
        });
    },
    openPreview(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/scss/_variables.scss";
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
  flex-wrap: wrap;
}
.pointer {
  cursor: pointer;
}

.inc-vat {
  font-size: 16px;

  justify-self: center;
}
</style>
